$selectedDateBackground: #cce4f6;

@include respond-to(phone) {
    #calendar button.ui-datepicker-trigger {
        display: none;
    }

    .ui-dialog {
        width: 100% !important;
        margin-bottom: 0;

        .ui-dialog-titlebar {
            background: none;
            margin-bottom: 5px;
            padding-bottom: 0;
        }
    }

    .ui-datepicker-row-break {
        display: none;
    }

    .ui-datepicker-multi {
        .ui-datepicker-group {
            float: none;
        }

        .ui-datepicker-group-first {
            margin-top: 0;
        }

        .ui-datepicker-group-middle {
            margin-top: 20px;
        }
    }

    .ui-datepicker {
        border-width: 0;
        width: 100% !important;

        table {
            @include font-size(21);
            font-family: $sansFontFamilyRegular;
            line-height: $baseLineHeight * 1.35;
        }

        th {
            padding: 0;
            text-align: center;
            font-weight: bold;
        }

        .ui-datepicker-header {
            background-color: $white;
            background: none;
        }

        .ui-datepicker-title {
            @include font-size(21);
            line-height: $baseLineHeight * 1.35;
            color: $mediumGray;
            font-family: $sansFontFamilyMedium;
        }

        .ui-datepicker-calendar {
            .ui-state-default {
                padding: 0.4em 0.45em;
            }

            thead span {
                @include font-size(14);
                font-family: $sansFontFamilyRegular;
                color: $mediumGray;
            }

            tbody td {
                padding: 0;
            }

            .ui-state-default {
                height: 100%;
                background-color: $white;
            }

            .ui-state-disabled {
                .ui-state-default {
                    text-decoration: none;
                    color: $lightGray !important;
                }
            }

            .ui-state-hover {
                background: $white;
                outline: none;
                text-decoration: none;
            }

            .ui-date-include a {
                background-color: $selectedDateBackground;
            }

            .ui-date-selected.-start {
                background: linear-gradient(90deg, $white 50%, $selectedDateBackground 50%);
            }

            .ui-date-selected.-end {
                background: linear-gradient(90deg, $selectedDateBackground 50%, $white 50%);
            }

            .ui-date-selected a,
            .ui-state-active {
                color: $white !important;
                font-weight: normal;
                border-radius: 50%;
                border: none;
                background: $brandBlue;
                -moz-border-image: none; //-moz-linear-gradient(top, $brandBlue 0%, $darkBlue 100%);
                -webkit-border-image: none; // -webkit-linear-gradient(top, $brandBlue 0%, $darkBlue 100%);
                border-image: none; //linear-gradient(to bottom, $brandBlue 0%, $darkBlue 100%);
                border-image-slice: none;
            }

            .ui-state-highlight::before,
            .aa-highlight .ui-state-default.ui-state-highlight::before,
            .ui-state-active.ui-state-highlight::before {
                border: none;
            }
        }
    }

    .aa-ui-dialog {
        border: 0;
        padding: 10px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        .ui-dialog-titlebar {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 41px;
            z-index: 100;
            background-color: $white;

            .ui-dialog-title {
                padding-top: 5px;
                padding-left: 10px;
                padding-right: 25px;
            }

            .ui-button {
                top: 6px;
                right: 5px;
            }
        }

        .ui-dialog-content {
            padding-top: 36px;
        }
    }
}

@include respond-to(phone_small) {
    .ui-datepicker {
        .ui-datepicker-calendar {
            .ui-state-default {
                padding: 0.43em 0.57em;
            }
        }
    }
}
